<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1>
                <small>Module</small> {{ module ? module.name : "inconnu" }}
            </h1>
        </div>
        <div class="k-page-body k-flex-col" v-if="module">
            <b-form-group>
                <label>
                    Cible du module <span class="text-danger">*</span>
                </label>
                <b-form-radio-group v-model="target">
                    <b-form-radio
                        v-for="t in targetOptions"
                        :key="t.type"
                        :value="t.type"
                        :disabled="!targets.includes(t.type)"
                    >
                        {{ t.label }}
                        <small v-show="!targets.includes(t.type)">
                            (indisponible)
                        </small>
                    </b-form-radio>
                </b-form-radio-group>
                <b-form-invalid-feedback :state="isTargetValid">
                    Veuillez sélectionner la cible du module
                </b-form-invalid-feedback>
            </b-form-group>

            <FormProjectModule
                v-if="formConfig"
                class="border rounded p-3 bg-light"
                :project-id="projectId"
                :config="formConfig"
                v-model="config"
                @validate="isFormValid = $event"
            />

            <div class="k-flex-center k-gap-10 mt-2">
                <b-button variant="kalkin-2" :disabled="!isValid" @click="save">
                    Enregister
                </b-button>
            </div>
        </div>
        <div v-else>
            Module non trouvé
        </div>
    </div>
</template>

<script>
import { get as _get } from "lodash";
import Module from "@/model/modules";
import FormProjectModule from "@/components/model/projectmodule/FormProjectModule";

export default {
    components: {
        FormProjectModule,
    },

    data: function() {
        return {
            projectId: this.$route.params.projectId,
            moduleId: this.$route.params.moduleId,

            targetOptions: Module.getModuleTargets(),

            module: null,
            target: null,
            config: null,

            isFormValid: false,
        };
    },

    computed: {
        targets() {
            const config = this.module && this.module.config;
            return (config && config.target) || [];
        },

        formConfig() {
            const config = this.module && this.module.config;
            const admin = config && config.admin;
            for (const p in admin) {
                return admin; // On retourne si non vide
            }
            return false;
        },

        isTargetValid() {
            return this.target !== null;
        },

        isValid() {
            return this.isTargetValid && (this.isFormValid || !this.formConfig);
        },
    },

    methods: {
        async fetchModule() {
            this.module = await this.$store.dispatch("admin/getProjectModule", {
                projectId: this.projectId,
                moduleId: this.moduleId,
            });
            const data = _get(this.module, "ProjectModule.data") || {};
            this.config = data.config || null;
            this.target = data.target || null;
        },

        async save() {
            await this.$store.dispatch("admin/updateProjectModule", {
                projectId: this.projectId,
                moduleId: this.moduleId,
                target: this.target,
                config: this.config,
            });
        },
    },

    async beforeMount() {
        await this.fetchModule();
    },
};
</script>
