<template>
    <div>
        <template v-if="config.type === 'form'">
            <GenericForm
                v-bind="genericFormConfig"
                @updated="$emit('input', $event)"
                @validated="onGenericFormValidated"
            />
        </template>
        <template v-else-if="config.type === 'component'">
            <component
                :is="`fpm-${config.component}`"
                :project-id="projectId"
                :config="config"
                :data="value"
                @input="$emit('input', $event)"
                @validate="$emit('validate', $event)"
                @save="$emit('save', $event)"
            />
        </template>
        <template v-else>
            <div class="text-center my-5">
                <i>Aucune information disponible pour ce module</i>
            </div>
        </template>
    </div>
</template>

<script>
import Vue from "vue";
import GenericForm from "@/components/form-generator/GenericForm";
import Formatter from "@/tools/format";
import notifier from "@/tools/notifier";

export default {
    components: {
        GenericForm,
    },

    props: {
        projectId: { type: [String, Number], required: true },
        config: { type: Object },
        value: { type: Object },
    },

    data: function() {
        let schema, localData;

        if (this.config.type === "form") {
            schema = this.createSchema();
            localData = this.initLocalData(schema);
        }

        return {
            schema,
            localData,
            project: null,
        };
    },

    computed: {
        genericFormConfig() {
            return {
                schema: this.schema,
                model: this.localData,
                payload: { project: this.project },
            };
        },
    },

    methods: {
        initLocalData(schema) {
            if (this.value) {
                return Vue.util.extend({}, this.value);
            } else {
                if (this.config.type === "form") {
                    return Formatter.createDefaultFORMObject(schema);
                }
                return {};
            }
        },

        createSchema() {
            try {
                return {
                    ...Formatter.convertCOREtoFORM(this.config.config),
                };
            } catch (error) {
                notifier.error(
                    "Une erreur s'est produite, essayez plus tard. <br/>Veuillez nous contacter si l'erreur persiste.",
                );
                return { fields: [] };
            }
        },

        onGenericFormValidated(state) {
            this.$emit("validate", state);
            if (state) {
                this.$emit("save");
            }
        },
    },

    async beforeMount() {
        if (this.config.type === "form") {
            this.project = await this.$store.dispatch("project/getProject", {
                projectId: this.projectId,
            });
        }
    },
};
</script>
